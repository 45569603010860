import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaSearch } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import axios from 'axios';

// import BlogGridTwo from '../blog/BlogGridTwo';


// Function to truncate HTML content while preserving tags
function truncateHtml(text, maxLength) {
    const truncated = text.replace(/<[^>]*>?/gm, ''); // Remove HTML tags
    return truncated.length > maxLength ? truncated.slice(0, maxLength) + '...' : truncated;
  }

const BlogGridOne = () => {
    const [posts, setPosts] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const blogsPerPage = 12;
    const pageVisited = pageNumber * blogsPerPage;

    useEffect(() => {


        axios
            .get(
                // `http://localhost/wordpress/wp-json/wp/v2/posts?per_page=100`
                 `https://mulesoft.dwinsoft.in/blog`

                // {
                //     headers: {
                //       "Cache-Control": "no-cache",
                //       "Content-Type": "application/json",
                //       "Access-Control-Allow-Origin": "*",
                //     },
                //   }

                // {
                //     headers: {
                //         'Cache-Control': 'no-cache',
                //         'User-Agent': 'PostmanRuntime/7.43.0',
                //         'Accept': '*/*',
                //         'Accept-Encoding': 'gzip, deflate, br',
                //         'Connection': 'keep-alive',
                //     }
                // }
            )
            .then((response) => {

                const posts = response.data;

                setPosts(posts);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    return (
        <>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '1800px', margin: '2rem auto', gap: '2rem' }} >
                {/* {posts.map((post) => ( */}
                {posts.slice(pageVisited, pageVisited + blogsPerPage).map((post) => (

                    <div className='cardHover' style={{ background: '', display: 'flex', flexDirection: 'column', width: 'clamp(20rem, calc(20rem + 2vw), 22rem)', overflow: 'hidden', boxShadow: '0 .4rem 1rem rgba(0, 0, 0, 0.1)', borderRadius: '1em', transition: 'border-bottom 0.3s', borderBottom: '6px solid transparent', }} onMouseEnter={(e) => {
                        e.currentTarget.style.borderBottom = '6px solid #89CFF0'; // Apply red border on hover
                        e.currentTarget.style.borderBottom = '6px solid #89CFF0'; // Apply green border on hover
                        e.currentTarget.style.boxShadow = 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'; 
                        e.currentTarget.style.transform = 'scale(1.05)';
                    }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.borderBottom = '6px solid transparent'; // Reset border when not hovered
                            e.currentTarget.style.borderBottom = '6px solid transparent'; // Reset border when not hovered
                            e.currentTarget.style.boxShadow= "none"; 
                        }
                       }>


                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', transition: 'box-shadow 0.3s' }}    >
                        
                            <div className="post-thumbnail" style={{ overflow: 'hidden', width: '350px', height: '210px', borderRadius: '8px' }}>
                                <Link to={process.env.PUBLIC_URL + `/blog-details/${post.slug}`}>
                                    <div class="img-hover-zoom">
                                    <img  onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.15)'; }}   onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)';  }} width="350" height="350" src={post.image_url} alt={post.title.rendered} style={{ maxWidth: '100%', transition: 'transform 0.4s ease', }} loading="lazy" />
                                    </div>
                                </Link>
                            </div>
                           
                        </div>
                        <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                            <span>{new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span>
                            <h6 className="title">
                                <Link to={process.env.PUBLIC_URL + `/blog-details/${post.slug}`}><b>{post.title.rendered}</b></Link>
                            </h6>
                            {/* <p>{extractFirstParagraph(post.contentBody.content,100)}. . .</p> */}

                            <div style={{fontSize:"15px"}} dangerouslySetInnerHTML={{ __html: truncateHtml(post.excerpt.rendered, 100) }} />
                            {/* <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} /> */}
{/* 
                            <div style={{ display: 'flex', marginTop: 'auto', }}>
                                <Link   className="axil-btn btn-borderd" to={process.env.PUBLIC_URL + `/blog-details/${post.slug}`}>Read More by S</Link>
                            </div> */}
                        </div>


                    </div>

                ))}
            </div>




            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ReactPaginate
                    previousLabel={<FaArrowLeft />}
                    nextLabel={<FaArrowRight />}
                    pageCount={Math.ceil(posts.length / blogsPerPage)}
                    onPageChange={changePage}
                    containerClassName={'pagination'}
                    previousLinkClassName={'prev'}
                    nextLinkClassName={'next'}
                    disabledClassName={'disabled'}
                    activeClassName={'current'}
                />
            </div>


        </>
    );
};

export default BlogGridOne;